<template>
  <v-container v-if="has_view_permission">
    <v-card class="my-1 pa-3 blue accent-1">
      <v-card-title>
        <AddEdit v-if="add_button" activator_name="Add New"
                 form_title="Add New"
                 activator_icon="mdi-plus-thick" action="add" @form_status="get_list_data"
                 big_btn="true" @snackbar_st="snackbars"></AddEdit>
        <v-btn :loading="reload_btn" @click="get_list_data" class="ma-1">
          <v-icon>
            mdi-reload
          </v-icon>
          Reload Data Table
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card class="yellow lighten-2">
      <v-data-table
          :headers="column_headers"
          :items="table_items"
          sort-by="id"
          class="elevation-5 yellow lighten-2"
          :loading="table_loading"
          :search="search"
          loading-text="Loading... Please wait"
          item-key="id"
          name="data_table"
          show-expand
          single-expand
      >
        <template v-slot:top>
          <v-card-title
              flat
          >
            {{ tableTitle }}
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <AddEdit v-if="edit_button" :key="item.id + '_add_btn'"
                   :form_title="`Edit Subject# ${item.name}`" :passed_item="item"
                   activator_icon="mdi-pencil" action="edit" @form_status="get_list_data" @snackbar_st="snackbars"
          ></AddEdit>
          <delete_dialog v-if="delete_button" @form_status="get_list_data" :passed_item="item"
                         :dialogue_text="`Are you sure you want to delete item# ${item.name}?`"
                         :url="`${nls_urls.subject.delete.replace('{id}', item.id)}`"
                         icon="mdi-delete" tooltip_text="Delete" @snackbar_st="snackbars"
                         action="delete" :key="item.id + '_delete'"></delete_dialog>
        </template>
        <template v-slot:expanded-item="{ headers,item}">
          <td :colspan="headers.length" :key="item.id">
            <NLSOwner tableTitle="Owners" :nlsItem="item"/>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import createAxiosClient from "@/helpers/axios_helper.js";
import delete_dialog from "@/components/user_components/user-sub_component/delete_dialog.vue";
import nls_urls from "@/constants/urls/nls_urls.js"
import nls_perms from "@/constants/permissions/nls_perms.js"
import AddEdit from "@/components/nls_subject/sub_component/AddEdit.vue";
import NLSOwner from "@/components/nls_subject/owner_component/NLSOwner.vue";


export default {
  name: "NLSListTable",
  components: {NLSOwner, AddEdit, delete_dialog},
  props: ["tableTitle"],
  computed: {
    nls_urls() {
      return nls_urls
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers(nls_perms.subject.list)
    },
    edit_button() {
      return this.$store.getters.permissionCheckers(nls_perms.subject.edit)
    },
    delete_button() {
      return this.$store.getters.permissionCheckers(nls_perms.subject.delete)
    },
    add_button() {
      return this.$store.getters.permissionCheckers(nls_perms.subject.add)
    },
  },
  data: () => ({
    reload_btn: false,
    table_loading: false,
    column_headers: [
      {
        text: 'Tracking Number',
        align: 'center',
        filterable: true,
        value: 'tracking_number',
      },
      {
        text: 'Name',
        align: 'center',
        filterable: true,
        value: 'name',
      },
      {
        text: 'Branch Code',
        align: 'center',
        filterable: true,
        value: 'branch.code',
      },
      {
        text: 'Branch Name',
        align: 'center',
        filterable: true,
        value: 'branch.name',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'actions',
      },
      {
        text: 'Owner Details ',
        align: 'center',
        filterable: false,
        value: 'data-table-expand',
      },
    ],
    table_items: [],
    search: '',
    show_snackbar: false,
    snackbar_text: null,
  }),
  methods: {
    async get_list_data() {
      let client = createAxiosClient()
      this.reload_btn = true
      this.table_loading = true
      await client.get(nls_urls.subject.list).then((resp) => {
        // console.log(resp)
        this.table_items = resp.data.data
        this.$store.commit('setJWT', resp.data.key)
      })
      this.reload_btn = false
      this.table_loading = false
    },
    snackbars(value) {
      // console.log(value)
      this.snackbar_text = value
      this.show_snackbar = true
    }
  },
  mounted() {
    this.get_list_data();
  }
}
</script>

<style scoped>
tbody tr:hover {
  background: #82B1FF !important;
}
</style>