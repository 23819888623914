<template>
  <v-container>
    <NLSListTable tableTitle="Non Loan Subject"></NLSListTable>
  </v-container>
</template>
<script>


import NLSListTable from "@/components/nls_subject/NLSListTable.vue";

export default {
  name: "NlsListPage",
  components: {NLSListTable}
}
</script>

<style scoped>

</style>