var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},on:{"input":_vm.get_zones},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.big_btn)?_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]),_vm._v(" "+_vm._s(_vm.activator_name)+" ")],1):_vm._e(),(!_vm.big_btn)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"vform",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handle_submit)}}},[_c('v-card',{staticClass:"light-blue lighten-3"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.form_title))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"code","name":"Branch Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Branch Code*","hint":"Branch Code","required":"","autocomplete":"nope","maxlength":"20","counter":""},model:{value:(_vm.branch.code),callback:function ($$v) {_vm.$set(_vm.branch, "code", $$v)},expression:"branch.code"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"name","name":"Branch name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name*","hint":"Zone Name","required":"","autocomplete":"nope","maxlength":"256","counter":""},model:{value:(_vm.branch.name),callback:function ($$v) {_vm.$set(_vm.branch, "name", $$v)},expression:"branch.name"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"zone","name":"Zone name","rules":"objectNotEmpty:code|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Select a zone*","hint":"The zone where this branch resides","items":_vm.zones,"item-text":"name","clearable":"","item-value":"id","item-color":"primary"},model:{value:(_vm.branch.zone),callback:function ($$v) {_vm.$set(_vm.branch, "zone", $$v)},expression:"branch.zone"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","loading":_vm.submit_loading}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }