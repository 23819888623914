<template>
  <v-container>
    <branch-list-table></branch-list-table>
  </v-container>
</template>

<script>
import BranchListTable from "@/components/office_components/BranchListTable";

export default {
  name: "BranchListPage",
  components: {BranchListTable},
  mounted() {
    document.title = 'Agrani Bank Legal Suit System | Branch List';
  }
}
</script>

<style scoped>

</style>